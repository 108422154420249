import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
  actions,
  AuthError,
  ChooseModality,
  DeviceList,
  Email,
  Footer,
  ImprivataIdEnroll,
  ImprivataIdManual,
  ImprivataIdPush,
  Otp,
  Password,
  PasswordReset,
  Pin,
  Sms,
  SmsEnroll,
  TempCode,
  UsernamePassword,
  WaitingForEmr,
  ResumeAuthFlow
} from '@imprivata/common-auth-ui';

import {LoadingOverlay} from '@imprivata/nucleus';

class CommonAuthUserInterfaceWrapper extends Component {
  static propTypes = {
    startSession: PropTypes.func,
    modality: PropTypes.string,
    sessionException: PropTypes.object,
    sessionStarted: PropTypes.bool,
    startSessionEstablishing: PropTypes.bool,
    username: PropTypes.string,
    password: PropTypes.string,
    workflow: PropTypes.string,
    cidMobileServiceInfo: PropTypes.object,
    cidMobileServiceInfoInput: PropTypes.object,
    cidErrorCode: PropTypes.string,
    authenticationToken: PropTypes.string,
    complete: PropTypes.bool,
    isLoading: PropTypes.bool,
    contextDataToken: PropTypes.string,
    cloudEnv: PropTypes.string,
    ssdmEnv: PropTypes.string,
    product: PropTypes.string,
    onAuthTokenReceived: PropTypes.func,
    onWsFedResultReceived: PropTypes.func,
    onErrorReceived: PropTypes.func,
    audience: PropTypes.string,
    responseURL: PropTypes.string
  };

  logRed = (message) => {
    console.log('%c' + new Date().toLocaleString() + ' %c' + message, 'color: #C61F2C', 'color:#61b84d');
  };

  async componentDidMount() {
    this.setUsername(this.props.usernameInput);
    this.setResumeAuthFlowTokenUrl(this.props.resumeAuthFlowTokenUrlInput);
    this.setErrorHandlerUrl(this.props.errorHandlerUrlInput);
    this.setForceMfa(this.props.forceMfaInput)
    this.startSession();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {

    if (!prevProps.complete && this.props.complete) {
      this.logRed(`Authentication token: ${this.props.authenticationToken}`); // eslint-disable-line
      if (this.props.onAuthTokenReceived) {
        this.props.onAuthTokenReceived(this.props.authenticationToken);
      }
    }
    if (!prevProps.wsFedWresult && this.props.wsFedWresult) {
      if (this.props.onWsFedResultReceived) {
        this.props.onWsFedResultReceived(this.props.wsFedWa, this.props.wsFedWctx, this.props.wsFedWresult, this.props.wsFedUrl);
      }
    }
    if (!this.props.sessionStarted  && !this.props.sessionException && !this.props.startSessionEstablishing) {
      await this.startSession();
    }

    //error handling only for cidMobile errors
    if(this.props.sessionException !== null && this.props.onErrorReceived && prevProps.sessionException !== this.props.sessionException)
    {
      const errorCode = encodeURIComponent(this.props.cidErrorCode) + '';
      const errorMessage = this.props.cidErrorMessage;
      this.props.onErrorReceived(errorCode, errorMessage);
    }
  }

  startSession = async () => {
    this.logRed(`Starting session for ` + this.props.product);
    if (!this.props.sessionStarted && !this.props.sessionException) {
      let sessionUsername = (this.props.usernameInput ? this.props.usernameInput : this.props.username);
      let sessionWorkflow = (this.props.workflowInput ? this.props.workflowInput : this.props.workflow);

      this.logRed(`Context token: ${this.props.contextDataToken}, Environment: ${this.props.cloudEnv}, SelfServiceEnvironment: ${this.props.ssdmEnv}`);
      this.props.startSession(this.props.contextDataToken, this.props.cloudEnv, this.props.ssdmEnv,
          sessionUsername, sessionWorkflow, this.props.cidMobileServiceInfoInput, this.props.requestContext);
    }
  };

  setUsername = async  (username) => {
    this.props.setInitialUsername(username)
  }

  setResumeAuthFlowTokenUrl = async  (resumeAuthFlowTokenUrl) => {
    this.props.setResumeAuthFlowTokenUrl(resumeAuthFlowTokenUrl)
  }

  setErrorHandlerUrl = async (errorHandlerUrl) => {
    this.props.setErrorHandlerUrl(errorHandlerUrl)
  }

  setForceMfa = async  (forceMfa) => {
    this.props.setForceMfa(forceMfa)
  }

  render() {
    if (!this.props.sessionStarted && !this.props.sessionException) {
      return (
          <LoadingOverlay showOverlay/>
      );
    }
    this.logRed('Modality: ' + this.props.modality);
    return (
        <div>
          <Switch>
            <Route path="/autherror" component={AuthError}/>
            <Route path="/choosemodality" component={ChooseModality}/>
            <Route path="/devicelist" component={DeviceList}/>
            <Route path="/email" component={Email}/>
            <Route path="/enrollsms" component={SmsEnroll}/>
            <Route path="/enrollimprivataid" component={ImprivataIdEnroll}/>
            <Route path="/iid-manual" component={ImprivataIdManual}/>
            <Route path="/iid" component={ImprivataIdPush}/>
            <Route path="/otp" component={Otp}/>
            <Route path="/password" component={Password}/>
            <Route path="/password-reset" component={PasswordReset}/>
            <Route path="/pin" component={Pin}/>
            <Route path="/sms" component={Sms}/>
            <Route path="/username-password" component={UsernamePassword}/>
            <Route path="/tempcode" component={TempCode}/>
            <Route path="/waitingforemr" component={WaitingForEmr}/>
            <Route path="/resume-auth-flow" component={ResumeAuthFlow}/>
          </Switch>
          <Footer/>
          <LoadingOverlay showOverlay={this.props.isLoading}/>
        </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    sessionStarted, startSessionEstablishing, modality, sessionException, isLoading, username, password, cidMobileServiceInfo,
    authenticationToken, complete, serverState, manuallyProceedToTempCode, cidErrorCode, cidErrorMessage, sessionVersion,
    wsFedWa, wsFedWctx, wsFedWresult, wsFedUrl,} = state.login;
  return {
    sessionStarted,
    startSessionEstablishing,
    modality,
    sessionException,
    username,
    password,
    cidMobileServiceInfo,
    authenticationToken,
    complete,
    isLoading,
    serverState,
    manuallyProceedToTempCode,
    cidErrorCode,
    cidErrorMessage,
    sessionVersion,
    wsFedWa,
    wsFedWctx,
    wsFedWresult,
    wsFedUrl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startSession: (contextDataToken, environment, selfServiceEnvironment, username, workflow, cidMobileServiceInfo, requestContext) => {
      dispatch(actions.startSession(contextDataToken, environment,
          selfServiceEnvironment, username, workflow, cidMobileServiceInfo, requestContext));
    },
    setInitialUsername: (username) => {
      dispatch(actions.setInitialUsername(username))
    },
    setResumeAuthFlowTokenUrl: (resumeAuthFlowTokenUrl) => {
      dispatch(actions.setResumeAuthFlowTokenUrl(resumeAuthFlowTokenUrl))
    },
    setErrorHandlerUrl: (errorHandlerUrl) => {
      dispatch(actions.setErrorHandlerUrl(errorHandlerUrl))
    },
    setForceMfa: (forceMfa) => {
      dispatch(actions.setForceMfa(forceMfa))
    }
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (CommonAuthUserInterfaceWrapper));
