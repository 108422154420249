import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import CAUIWrapper from '../common/CommonAuthUserInterfaceWrapper';
import './WebLogin.scss';
import queryString from 'query-string';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import {AuditCookieKeys} from "../common/CookieKeys";

class WebLogin extends Component {
  constructor(props) {
    super(props);
    this.queryParams = queryString.parse(document.location.search);
    this.state = {
      requestToken: Cookies.get('RequestToken'),
      authRequestId: this.getDecodedRequestId(),
      applianceUrl: null,
      timeoutId: null
    };
  }

  getDecodedRequestId = () => {
    const jwtToken = Cookies.get('RequestToken');
    if (jwtToken) {
      const decodedToken = jwt_decode(jwtToken);
      if (decodedToken != null) {
        return decodedToken.requestId;
      }
    }
    return '';
  }

  handleApplianceUrlChange = (event) => {
    event.persist();
    let spinnerRound = document.getElementById("spinner-round");
    // eslint-disable-next-line no-undef
    show(spinnerRound);
    this.visuallyHideCaui();
    let timeout = setTimeout(function () {
      console.log("Waited for IMDA response for 10 seconds.");
      this.showCaui();
      // eslint-disable-next-line no-undef
      hide(spinnerRound);
    }, 10000);
    this.setState(() => (
      {
        applianceUrl: event.target.value,
        timeoutId: timeout
      }
    ));
  }

  corsAuthRequestToAppliance = async () => {
    console.log("CAUI WRAPPER: Starting IMDA authentication flow. Requesting token from: " + this.state.applianceUrl);
    let spinnerRound = document.getElementById("spinner-round");
    if (this.state.timeoutId) {
      clearTimeout(this.state.timeoutId);
    }
    // eslint-disable-next-line no-undef
    show(spinnerRound);
    this.visuallyHideCaui();
    if (this.state.authRequestId == null || this.state.authRequestId === ''
      || this.state.applianceUrl == null || this.state.applianceUrl === '') {
      console.log('Request Id or Appliance URL is missing');
      this.showCaui();
      // eslint-disable-next-line no-undef
      hide(spinnerRound);
      return;
    }
    await fetch(this.state.applianceUrl + '/sso/rest/v1/seamless_auth', {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        authRequestId: this.state.authRequestId
      })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Seamless Auth response was not ok');
        } return response;
      })
      .then(response => response.json())
      .then(json => {
        console.log("CAUI WRAPPER: IMDA authentication flow - received auth token from appliance");
        var agent = document.getElementById("agent");
        if (agent) {
          agent.value = "IMDA";
        }
        if(json.authToken){
          console.log("CAUI WRAPPER: IMDA authentication flow - completed and submitting token to IdP");
          this.sendSuccessResponse(json.authToken);
        } else {
          //Handle server error response
          //{"code":"Failed"}
          this.showCaui();
          // eslint-disable-next-line no-undef
          hide(spinnerRound);
          console.error(
              'There has been a problem with fetch operation:',
              json.code
          );
        }
      })
      .catch(error => {
        this.showCaui();
        // eslint-disable-next-line no-undef
        hide(spinnerRound);
        console.error(
          'There has been a problem with fetch operation:',
          error
        );
      });
  }

  sendSuccessResponse = token => {
    let authTokenElement = document.getElementById('auth_token');
    authTokenElement.value = token;
    let authRequestIdElement = document.getElementById('auth_request_id');
    authRequestIdElement.value = this.state.authRequestId;
    let authForm = document.getElementById('auth_form');
    authForm.submit();
  }

  sendWsFedResult = (wa, wctx, wresult, url) => {
    let waElement = document.getElementById('wa');
    waElement.value = wa;
    let wctxElement = document.getElementById('wctx');
    wctxElement.value = wctx;
    let wresultElement = document.getElementById('wresult');
    wresultElement.value = wresult;
    let wsFedForm = document.getElementById('ws_fed_form');
    wsFedForm.setAttribute('action', url);
    wsFedForm.submit();
  }

  visuallyHideCaui = () => {
    let caui = document.getElementById("caui");
    if (caui && !caui.classList.contains("visually-hidden")) {
      caui.classList.add("visually-hidden");
    }
  }

  showCaui = () => {
    let caui = document.getElementById("caui");
    if (caui && caui.classList.contains("visually-hidden")) {
      caui.classList.remove("visually-hidden");
    }
  }

  getAuditInfoFromCookies = () => {
    const auditInfoCookieKeys = Object.values(AuditCookieKeys);
    const auditInfo = {};
    auditInfoCookieKeys.forEach(key => {
      let cookieValue = Cookies.get(key);
      if (cookieValue) {
        auditInfo[key] = cookieValue;
      }
    });
    return Object.keys(auditInfo).length > 0 ? auditInfo : null;
  }

  //TODO: temporary stubs left as a params values for dev testing
  render() {
    const {
      token, cloudEnv, ssdmEnv, username, workflow, appName, partnerName,
      transactionId, audience, deviceModel, deviceName, responseDomain, forceAuthn,
      resumeAuthFlowTokenUrl, forceMfa, errorHandler
    } = this.queryParams;
    const isAndroidUserAgent = navigator.userAgent.match("Android");

    return (
      <div className="app">
        <CAUIWrapper
          contextDataToken={token ? token : 'testToken'}
          cloudEnv={cloudEnv ? cloudEnv : 'applex.dev.common.imprivata.com'}
          ssdmEnv={ssdmEnv ? ssdmEnv : 'ssdm.dev.common.imprivata.com'}
          product={'WebLogin'}
          onAuthTokenReceived={this.sendSuccessResponse}
          onWsFedResultReceived={this.sendWsFedResult}
          usernameInput={username}
          resumeAuthFlowTokenUrlInput={resumeAuthFlowTokenUrl ? 'https://' + responseDomain + '.imprivata.com/' + resumeAuthFlowTokenUrl : null}
          errorHandlerUrlInput={errorHandler ? 'https://' + responseDomain + '.imprivata.com/' + errorHandler : null}
          forceMfaInput={(String(forceMfa).toLowerCase() === 'true')}
          workflowInput={workflow}
          cidMobileServiceInfo={
            {
              optionalPartnerName: partnerName,
              optionalAppName: appName,
              optionalTransactionId: transactionId,
              optionalAudience: audience,
              optionalDeviceInfo: {
                optionalDeviceModel: deviceModel,
                optionalDeviceName: deviceName
              },
              optionalCapabilities: null
            }
          }
          requestContext={this.getAuditInfoFromCookies()}
        />
        {isAndroidUserAgent && forceAuthn !== "true" ?
          <div className='visually-hidden'>
            <span id='impr_request_token'>{this.state.requestToken}</span>
            <input id='impr_appliance_url' type='text' onChange={this.handleApplianceUrlChange}/>
            <button id='impr_seamless_auth' onClick={this.corsAuthRequestToAppliance}/>
          </div>
          : null}
      </div>
    )
  }
}

export default withRouter(WebLogin);
